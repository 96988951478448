import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Navigate, Route, Routes } from "react-router-dom";
import { fas } from "@fortawesome/free-solid-svg-icons";
import HomepageContainer from "./container/HomepageContainer";
import EditorContainer from "./container/EditorContainer";
import AuthContainer from "./container/AuthContainer";
import ProtectedRoute from "./modules/ProtectedRoute";
import AccessDenied from "./components/AccessDenied/AccessDenied";
import DashboardContainer from "./container/DashboardContainer";
import SubscriptionContainer from "./container/SubscriptionContainer/SubscriptionContainer";
import PaymentSuccessContainer from "./container/PaymentSuccessContainer";
import ListOfProject from "./container/DashboardContainer/ListOfProject";
import Docs from "./modules/LayoutModule/Content/Docs/Docs"; // Add this import
import CategoryArticles from "./modules/LayoutModule/Content/Docs/CategoryArticles"; // Add this import
import Marketplace from "./container/Marketplace/Marketplace"; // Update the import
import SingleProject from './container/Marketplace/SingleProject'; // Import the SingleProject component
import UserProfile from "./container/DashboardContainer/UserProfile"; // Import the UserProfile component
import EditProject from "./container/DashboardContainer/EditProject"; // Import the EditProject component
import BlogList from "./container/BlogContainer/BlogList"; // Import the BlogList component
import BlogArticle from "./container/BlogContainer/BlogArticle"; // Import the BlogDetail component
import AddNewProject from "./container/DashboardContainer/NewProject"; // Import the AddNewProject component
import MyFiles from "./container/DashboardContainer/MyFiles"; // Import the MyFiles component
import ResetPassword from "./components/ResetPassword/ResetPassword"; // Import the ResetPassword component
import ForgotPassword from "./components/ResetPassword/ForgotPassword"; // Import the ForgotPassword component
import DeployLMS from "./container/DashboardContainer/DeployLMS"; // Import the DeployLMS component
import ProjectHistory from "./container/DashboardContainer/ProjectHistory"; // Import the ProjectHistory component
import LMSContainer from "./container/UserLMSContainer/LMSContainer"; // Import the LMSContainer component
import IntraMarketPlace from "./container/DashboardContainer/IntraMarketPlace"; // Import the IntraMarketPlace component
import Checkout from "./utils/Checkout"; // Import the Checkout component
import IntraMarketPlaceSingle from "./container/DashboardContainer/IntraMarketPlaceSingle"; // Import the IntraMarketPlaceSingle component


library.add(fas);

function App() {
  return (
    <div className="h-[100vh]">
      <Routes>
        <Route path="/" element={<HomepageContainer />} />
        <Route path="/login" element={<AuthContainer login />} />
        <Route path="/signup" element={<AuthContainer signup />} />
        <Route path="/reset-password/:code" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/payment-success"
          element={<ProtectedRoute element={<PaymentSuccessContainer />} />}
        />
        <Route
          path="/subscription-details"
          element={<ProtectedRoute element={<SubscriptionContainer />} />}
        />
        <Route
          path="/dashboard"
          element={<ProtectedRoute element={<DashboardContainer />} />}
        />
        <Route
          path="/projects"
          element={<ProtectedRoute element={<ListOfProject />} />}
        />
        <Route
          path="/add-new-project"
          element={<ProtectedRoute element={<AddNewProject />} />}
        />
        <Route
          path="/my-files"
          element={<ProtectedRoute element={<MyFiles />} />}
        />
        <Route
          path="/intra-marketplace"
          element={<ProtectedRoute element={<IntraMarketPlace />} />}
        />
        <Route
          path="/intra-marketplace/:project_id"
          element={<ProtectedRoute element={<IntraMarketPlaceSingle />} />}
        />
        <Route
          path="/checkout"
          element={<ProtectedRoute element={<Checkout />} />}
        />
        <Route
          path="/your-lms"
          element={<ProtectedRoute element={<DeployLMS />} />}
        />
        <Route path="/lms/:organizationName/*" element={<LMSContainer />} />
        <Route
          path="/project-history/:projectId"
          element={<ProtectedRoute element={<ProjectHistory />} />}
        />
        <Route
          path="/editor/:id"
          element={<ProtectedRoute element={<EditorContainer />} />}
        />
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/docs" element={<Docs />} />
        <Route path="/docs/category/:slug" element={<CategoryArticles />} /> {/* Add this route */}
        <Route path="/marketplace" element={<Marketplace />} /> {/* Update this route */}
        <Route path="/project/:project_id/:project_name" element={<SingleProject />} /> {/* Update this route */}
        <Route path="/blog" element={<BlogList />} />
        <Route path="/blog/:id" element={<BlogArticle />} />
        <Route
          path="/profile"
          element={<ProtectedRoute element={<UserProfile />} />} // Add this route
        />
        <Route
             path="/edit-project/:project_id"
             element={<ProtectedRoute element={<EditProject />} />} // Add this route
           />
       
      </Routes>

    </div>
  );
}

export default App;
