import React, { useEffect, useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import RightPanel from '../../components/RightPanel/RightPanel';
import RevealSlide from '../../components/RevealSlide/RevealSlide';
import LinkViewPopup from '../../components/LinkViewPopup';
import JumpToSlidePopup from '../../components/JumpToSlidePopup';
import Header from '../../components/Header/Header';
import { useSelector } from 'react-redux';
import ContextMenu from '../../components/ContextMenu/ContextMenu';
import VideoControl from '../../components/VideoControls/VideoControls';
import Sidebar from '../../components/Sidebar/Sidebar';
import { Notes, NavArrowDown } from 'iconoir-react';
import { useSearchParams } from 'react-router-dom';

library.add(fas);

const EditorContainer = () => {
  const { activeObject, isContext, videoControl } = useSelector((state) => state.fabricCanvas);
  const { show: showCropper } = useSelector((state) => state.cropper);
  const [activeComponent, setActiveComponent] = useState('text');
  const [zoomLevel, setZoomLevel] = useState(100);  // Default zoom to 100%
  const [selectedResolution, setSelectedResolution] = useState('SD');  // Default resolution

  const [searchParams] = useSearchParams();
  const isPreviewMode = searchParams.get("isPreview") === "true";

  // Handle zoom level changes
  const handleZoomChange = (newZoomLevel) => {
    setZoomLevel(Math.min(200, Math.max(10, newZoomLevel)));  // Limit zoom range
  };

  // Handle resolution changes
  const handleResolutionChange = (resolution) => {
    setSelectedResolution(resolution);
  };

  useEffect(() => {
    if (activeObject && !showCropper) {
      // Determine the active component based on the selected object type
      const typeMap = {
        text: 'text',
        image: activeObject.name === 'video' ? 'video' : 'image',
        polygon: 'shape',
        circle: 'shape',
        path: 'shape',
        group: 'shape',
        line: 'shape',
        svg: 'shape',
        audio: 'audio',
        components: 'components',
        docs: 'docs',
        button: 'components',
        textInput: 'components',
        card: 'components',
        quiz: 'quiz',
        avatar: 'avatar',
      };
      setActiveComponent(typeMap[activeObject.type] || 'text');
    } else {
      setActiveComponent('text');
    }
  }, [activeObject, showCropper]);

  return (
    <>
      {!isPreviewMode && <Header />}
      <div
        className="flex h-screen"
        style={{ maxHeight: !isPreviewMode ? "calc(100vh - 80px)" : "100vh" }}
      >
        {!isPreviewMode && (
          <>
            {isContext && <ContextMenu />}
            {videoControl && <VideoControl />}
            <LinkViewPopup />
            <JumpToSlidePopup />
            <Sidebar onActiveComponentChange={setActiveComponent} />
            <RightPanel activeComponent={activeComponent} />
          </>
        )}
        <RevealSlide
          zoomLevel={zoomLevel}
          onZoomChange={handleZoomChange}
          selectedResolution={selectedResolution}
          onResolutionChange={handleResolutionChange}
          isPreviewMode={isPreviewMode}
        />
      </div>
      {!isPreviewMode && (
        <div className="h-35 flex bg-[#e1e1e1] items-start h-screen relative">
          <div className="flex justify-between w-full">
            <div className="flex items-center z-10 relative left-96 mb-1">
              <Notes className="w-3 h-3 text-gray-500 text-xxs font-bold cursor-pointer" />
              <p className="text-xxs font-gray-500 ml-1 cursor-pointer">Notes</p>
              <NavArrowDown className="w-3 h-3 text-gray-500 text-xxs font-bold cursor-pointer ml-1" />
            </div>
            <div className="relative z-10 block">
              <div className="flex justify-end items-center select-none mb-1">
                <div className="mx-2 text-xxs">{zoomLevel}%</div>
                <NavArrowDown className="w-4 h-4 font-bold cursor-pointer" />

                <div
                  className="w-5 h-5 flex items-center justify-center text-black text-xs font-bold cursor-pointer"
                  onClick={() => handleZoomChange(zoomLevel - 2)}
                >
                  <span className="select-none w-5 h-5 flex justify-center items-center">-</span>
                </div>
                <input
                  id="small-range"
                  type="range"
                  min="10"
                  max="200"
                  step="2"
                  value={zoomLevel}
                  onChange={(e) => handleZoomChange(parseInt(e.target.value))}
                  className="w-full h-1 mb-6 bg-gray-200 rounded-lg appearance-none cursor-pointer range-sm custom-slider"
                />
                <div
                  className="w-5 h-5 flex items-center justify-center text-black text-xs font-bold cursor-pointer mx-1"
                  onClick={() => handleZoomChange(zoomLevel + 2)}
                >
                  <span className="select-none w-5 h-5 flex justify-center items-center">+</span>
                </div>
                <select
                  value={selectedResolution}
                  onChange={(e) => handleResolutionChange(e.target.value)}
                  className="ml-4 p-1 border rounded text-xs"
                >
                  <option value="SM">SM</option>
                  <option value="SD">SD</option>
                  <option value="VGA">VGA</option>
                  <option value="HD">HD</option>
                  <option value="FullHD">FullHD</option>
                  <option value="QHD">QHD</option>
                  <option value="UHD">UHD</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditorContainer;
