import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Topbar from '../../Content/Topbar/Topbar';// Adjust the import path as necessary
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs'; // Adjust the import path as necessary
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import Footer from '../../Content/Footer/Footer';
import TextSearch from '../../../../components/TextSearch/TextSearch';


const CategoryArticles = () => {
  const { slug } = useParams(); // Get the category slug from the URL
  const [articles, setArticles] = useState([]);
  const [expandedArticle, setExpandedArticle] = useState(null);
  const [setSearchResults] = useState(articles);

  const toggleArticle = (id) => {
    setExpandedArticle(expandedArticle === id ? null : id);
  };

  const handleOnSearch = (string, results) => {
    setSearchResults(results);
  };

  const handleOnSelect = (item) => {
    const selectedArticle = articles.find(article => article.id === item.id);
    if (selectedArticle) {
      window.location.href = `/category/${slug}/article/${selectedArticle.id}`;
    }
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(`https://backend.ailms.co/api/articles?filters[category][Slug][$eq]=${slug}`);
        const data = await response.json();
        setArticles(data.data); // https://backend.ailms.co/api/categories
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, [slug]);

  return (
    <div>
      <Topbar />
       {/* Jumbotron with Search Bar */}
       <div className="relative text-white text-center w-full"> {/* Removed py-12 */}
          <img src="/header_image.png" alt="Header" className="absolute inset-0 w-full h-full object-cover" /> {/* Full width image */}
          <img src="/component4.png" alt="Component 4" className="absolute bottom-10 left-0 w-24 h-4" /> 
          <img src="/component4.png" alt="Component 4" className="absolute top-10 opacity-50 right-0 w-24 h-4" /> {/* Added image in the bottom left corner */}
          <div className="relative z-10 py-12"> {/* Added py-12 to inner div */}
            <h1 className="text-4xl font-bold mb-4">How Can We Help?</h1>
            <p className="mb-6">Our Docs are here to help</p>
            <div className="px-4 md:px-64"> {/* Added padding to the search box container */}
              <TextSearch
                items={articles.map(article => ({ id: article.id, name: article.attributes.Title }))}
                onSearch={handleOnSearch}
                onSelect={handleOnSelect}
                placeholder="Search for an article"
              />
            </div>
          </div>
        </div>
      <div className="p-4 md:p-8">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4 p-4 rounded-lg">
          <div className="flex items-center h-full"> {/* Center breadcrumbs vertically */}
            <Breadcrumbs category={slug} />
          </div>
          
        </div>
        <h2 className="text-2xl font-semibold mb-4">Articles in {slug}</h2>
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-2/3 pr-0 md:pr-4">
            {articles.length > 0 ? (
              articles.map((article) => (
                <div key={article.id} className="bg-white p-4 rounded-lg shadow mb-4">
                  <h3
                    className="text-lg font-semibold cursor-pointer"
                    onClick={() => toggleArticle(article.id)}
                  >
                    {article.attributes.Title}
                  </h3>
                  {expandedArticle === article.id && (
                    <div className="mt-2">
                      {article.attributes.Content ? (
                        <BlocksRenderer content={article.attributes.Content} />
                      ) : (
                        <p>No content available</p>
                      )}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>No articles found for this category.</p>
            )}
          </div>
          <div className="p-4 bg-custom-gray">
              <h2 className="text-2xl font-semibold mb-4">Need Support?</h2>
              <p>Can't find the answer you're looking for? We're here to help!</p>
              <button className="mt-4 bg-custom-lightBlue text-white p-4 rounded-lg w-full">Contact Support</button>
            </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CategoryArticles;
