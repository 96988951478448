import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAlignCenter, faAlignLeft, faAlignRight, faListOl, faListUl} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useSelector} from "react-redux";
import {listStyle} from "../../../utils/helpers";

const TextAlignmentAndListControl = () => {

    // State to track the text type selection
    const {activeSlide} = useSelector(state => state.slideList);
    const {activeObject, canvas} = useSelector(state => state.fabricCanvas);

    // Handler for text alignment selection
    const handleTextAlignment = (alignmentType) => {
        const {canvas} = activeSlide;
        activeObject.set({
            textAlign: alignmentType
        });
        canvas.renderAll();
    }

    return (
        <div className="mb-6 flex items-center justify-between space-x-4">
            <div className="flex items-center justify-center">
                <button
                    className="flex size-10 items-center justify-center rounded-l-md border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
                    onClick={() => handleTextAlignment('left')}>
                    <FontAwesomeIcon icon={faAlignLeft} className="size-3 text-gray-600"/>
                </button>
                <button
                    className="flex size-10 items-center justify-center border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
                    onClick={() => handleTextAlignment('center')}>
                    <FontAwesomeIcon icon={faAlignCenter}/>
                </button>
                <button
                    className="flex size-10 items-center justify-center rounded-r-md border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
                    onClick={() => handleTextAlignment('right')}>
                    <FontAwesomeIcon icon={faAlignRight}/>
                </button>
            </div>
            <div className="flex items-center justify-center ml-4">
                <button
                    className="flex size-10 flex-grow items-center justify-center rounded-l-md border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
                    onClick={() => listStyle(activeObject, 'bullet', canvas)}
                >
                    <FontAwesomeIcon icon={faListUl} className="size-3 text-gray-600"/>
                </button>
                <button
                    className="flex size-10 flex-grow items-center justify-center rounded-r-md border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
                    onClick={() => listStyle(activeObject, 'ordered', canvas)}
                >
                    <FontAwesomeIcon icon={faListOl} className="size-3 text-gray-600"/>
                </button>
            </div>
        </div>
       
        
    )
}
export default TextAlignmentAndListControl;