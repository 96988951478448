import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { api } from "../api/api";
import { useAlert } from "./AlertContext";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const idToken = urlParams.get("id_token");
  

  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
  });
  const { showAlert } = useAlert();

  const fetchUserInfo = useCallback(async () => {
    try {
      const response = await api.get("/api/users/me");
      if (response) {
        setAuthState({ isAuthenticated: true, user: response });
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  }, []);

  const login = async (data) => {
    console.log("Location state at login:", location.state); 
    try {
      const response = await api.post(
        "/api/auth/local",
        {
          identifier: data.email,
          password: data.password,
        },
        true
      );
  
      if (response?.jwt) {
        localStorage.setItem("token", response.jwt);
        setAuthState({ isAuthenticated: true, user: response.user });
  
        // Safely access location.state to avoid errors
        const redirectTo = location.state?.redirectTo || "/dashboard";
        const projectId = location.state?.project_id;
        const amount = location.state?.amount;
        console.log("Redirect to:", redirectTo, "Project ID:", projectId, "Amount:", amount);  // Check the values here
  
        // If the user was redirected from checkout, navigate to the checkout page
      if (redirectTo === "/checkout" && projectId && amount) {
        navigate("/checkout", {
          state: {
            project_id: projectId,
            amount: amount,
          },
        });
      } else {
        // Default redirection after login
        navigate(redirectTo);
      }
    }
  } catch (error) {
    showAlert("error", error?.response?.data?.error?.message);
  }
};
  

  const logout = () => {
    setAuthState({ isAuthenticated: false, user: null });
    localStorage.clear();
  };

  useEffect(() => {
    if (idToken) {
      const registerUser = async () => {
        const response = await api.post(
          "/api/google/auth/callback",
          {
            id_token: idToken,
          },
          true
        );
        if (response?.jwt) {
          localStorage.setItem("token", response.jwt);
          setAuthState({ isAuthenticated: true, user: response.user });
          navigate("/projects");
        }
      };
      registerUser();
    }
  }, [idToken, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && !idToken) {
      fetchUserInfo();
    }
  }, [navigate, fetchUserInfo, idToken]);

  return (
    <AuthContext.Provider
      value={{ ...authState, login, fetchUserInfo, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return authContext;
};

const useCheckSubscription = () => {
  const apiToken = process.env.REACT_APP_STRAPI_ADMIN_API_TOKEN;

  const checkSubscription = useCallback(async (user) => {
    try {
      const subscriptionResponse = await axios.get(
        `https://backend.ailms.co/strapi-stripe/getSubscriptionStatus/${user?.email}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );

      const productResponse = await axios.get(
        `https://backend.ailms.co/api/products`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );

      const products = productResponse.data || [];
      const subscriptions = subscriptionResponse.data?.data || [];

      const enrichedSubscriptions = subscriptions.map((subscription) => {
        const matchedProduct = products.find(
          (product) => product.stripePlanId === subscription.plan.id
        );
        return {
          ...subscription,
          product: matchedProduct || null,
        };
      });

      const isActive = enrichedSubscriptions.some(
        (subscription) => subscription?.plan?.active
      );

      return {
        isActive,
        data: enrichedSubscriptions, // Return the enriched data array
      };
    } catch (error) {
      console.error(error);
      return {
        isActive: false,
        data: null,
      };
    }
  }, [apiToken]);

  return checkSubscription;
};

const useRetrieveProjects = () => {
  const apiToken = process.env.REACT_APP_STRAPI_ADMIN_API_TOKEN;

  const retrieveProjects = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://backend.ailms.co/api/projects`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );
      return response.data?.projects || [];
    } catch (error) {
      console.error("Error retrieving projects:", error);
      return [];
    }
  }, [apiToken]);

  return retrieveProjects;
};

export { AuthProvider, AuthContext, useAuth, useCheckSubscription, useRetrieveProjects };
