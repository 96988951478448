import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";

function ResetPassword() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { code } = useParams(); // Assuming the reset code is passed as a URL parameter

  const onSubmit = async (data) => {
    try {
      await axios.post('http://localhost:1337/api/auth/reset-password', {
        code: code, // code from the reset link
        password: data.password,
        passwordConfirmation: data.passwordConfirmation,
      });
      alert("Password has been reset successfully.");
    } catch (error) {
      console.error('Reset error:', error);
      alert("An error occurred while resetting the password.");
    }
  };

  return (
    <div className="h-full bg-gray-100 flex flex-col justify-center">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="text-center text-3xl font-extrabold text-gray-900">
          Reset your password
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                New Password
              </label>
              <div className="mt-1">
                <input
                  name="password"
                  type="password"
                  placeholder="New Password"
                  {...register("password", { required: "Password is required" })}
                  className={`appearance-none rounded-md relative block w-full px-3 py-2 border ${
                    errors.password
                      ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                      : "border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  } placeholder-gray-500 text-gray-900 focus:outline-none focus:z-10 sm:text-sm`}
                />
                {errors.password && (
                  <p className="text-xs text-red-500">{errors.password.message}</p>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="passwordConfirmation"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm New Password
              </label>
              <div className="mt-1">
                <input
                  name="passwordConfirmation"
                  type="password"
                  placeholder="Confirm New Password"
                  {...register("passwordConfirmation", { required: "Please confirm your password" })}
                  className={`appearance-none rounded-md relative block w-full px-3 py-2 border ${
                    errors.passwordConfirmation
                      ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                      : "border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  } placeholder-gray-500 text-gray-900 focus:outline-none focus:z-10 sm:text-sm`}
                />
                {errors.passwordConfirmation && (
                  <p className="text-xs text-red-500">{errors.passwordConfirmation.message}</p>
                )}
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;